
import axios, {AxiosInstance} from 'axios';

export class ApiClient {

    private client: AxiosInstance;

    constructor(baseUrl: string, apiToken: string) {

        this.client = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `GeoHub ${apiToken}`
            }
        });
    }

    public requestDangerLevelOverride(level: number | null): Promise<void> {
        return this.client.post('api/v1/sandneslia/override', { overrideLevel:level })
            .then(r => r.data);
    }

    public getPermissions(): Promise<string[]> {
        return this.client.get('api/v1/permissions', { }).then(r => r.data.permissions);
    }
}
