import {Moment} from 'moment';
import * as moment from 'moment';

export class StationStateDtoMapper {

    public mapDto(dto: IStationStateDto): IStationState {
        return {
            avalancheDangerBulletin: dto.avalancheDangerBulletin != null ?  this.mapAvalancheDangerStateDto(dto.avalancheDangerBulletin) : null,
            requestedAvalancheDangerOverride: dto.requestedAvalancheDangerOverride != null ? this.mapAvalancheDangerStateDto(dto.requestedAvalancheDangerOverride) : null,
            activeAvalancheDangerOverride: dto.activeAvalancheDangerOverride != null ? this.mapAvalancheDangerStateDto(dto.activeAvalancheDangerOverride) : null,
            radarHealth: this.mapHealthStatus(dto.upperRadarHealth),
            overallDetectorHealth: this.mapHealthStatus(dto.overallDetectorHealth)
        };
    }

    private mapAvalancheDangerStateDto(dto: IAvalancheDangerStateDto): IAvalancheDangerState {
        return {
            timestamp: moment(dto.timestamp),
            dangerLevel: dto.dangerLevel,
            setBy: dto.setBy
        };
    }

    private mapHealthStatus(rawHealth: string): Health {
        return Health[rawHealth as keyof typeof Health];
    }
}

export interface IStationStateDto {
    avalancheDangerBulletin: IAvalancheDangerStateDto;
    requestedAvalancheDangerOverride: IAvalancheDangerStateDto;
    activeAvalancheDangerOverride: IAvalancheDangerStateDto;
    upperRadarHealth: string;
    overallDetectorHealth: string;
}

export interface IAvalancheDangerStateDto {
    timestamp: string;
    dangerLevel: number;
    setBy: string;
}

export interface IStationState {
    avalancheDangerBulletin: IAvalancheDangerState | null;
    requestedAvalancheDangerOverride: IAvalancheDangerState | null;
    activeAvalancheDangerOverride: IAvalancheDangerState | null;
    radarHealth: Health;
    overallDetectorHealth: Health;
}

export interface IAvalancheDangerState {
    timestamp: Moment;
    dangerLevel: number;
    setBy: string;
}

export enum Health {
    Unknown,
    Good,
    Degraded,
    Bad
}
